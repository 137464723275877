.about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin: 0;
  padding: 0 3rem;
  height: 85vh;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  line-height: 3em;
}

.about img {
  display: flex;
  justify-content: left;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 0;
}

p {
  font-size: xx-large;
  text-justify: distribute;
}

@media screen and (max-width: 720px) {
  .about {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: auto;
  }

  .about img {
    max-width: 25rem;
    padding-left: 3rem;
  }

  .title {
    padding: 0;
  }

  p {
    padding: 0 1rem;
    font-size: x-large;
    text-align: center;
  }
}

@media screen and (max-width: 1080px) {
  .about {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: auto;
  }

  .about img {
    max-width: 20rem;
    padding-left: 13rem;
  }

  .title {
    padding: 0;
  }

  p {
    padding: 0 1rem;
    font-size: x-large;
    text-align: center;
  }
}
