.footer {
  display: flex;
  flex-direction: column;
  background-color: black;
  margin-bottom: 0;
  padding: 2rem 3rem;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-media {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.logo-copy p {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  color: white;
  font-size: medium;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  position: relative;
  height: 6rem;
  width: 15rem;
  margin: 1rem;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  font-size: large;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #ff0000;
  border-radius: 25px;
  outline: none;
  overflow: hidden;
  color: rgb(255, 0, 0);
  transition: color 0.3s 0.1s ease-out;
  text-align: center;
}

button img {
  width: 1.5em;
  margin-right: 1.5rem; /* Espacio entre la imagen y el texto */
}

button a {
  text-decoration: none;
  color: #ff0000;
  display: flex;
  align-items: center; /* Centra verticalmente el texto dentro del botón */
  text-align: center; /* Centra horizontalmente el texto dentro del botón */
}

button::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  content: "";
  border-radius: 50%;
  display: block;
  width: 20em;
  height: 20em;
  left: -5em;
  text-align: center;
  transition: box-shadow 0.5s ease-out;
  z-index: -1;
}

button:hover {
  color: #fff; /* Color blanco para el texto */
  background: rgb(255, 0, 0); /* Color de fondo rojo */
}

button:hover img {
  filter: brightness(0) invert(1); /* Aplica efecto de negativo a la imagen para convertirla en blanco */
}

button:hover a {
  filter: brightness(0) invert(1); /* Aplica efecto de negativo a la imagen para convertirla en blanco */
}

button:hover::before {
  box-shadow: inset 0 0 0 10em rgb(255, 0, 0);
}

.button-img {
  width: 3rem;
}
