.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
  border-bottom: none;
}

.header img {
  padding: 0;
  display: flex;
  max-width: 10vw;
}

.navbar ul {
  list-style: none;
  text-decoration: none;
}

.navbar a {
  text-decoration: none;
  color: #ffffff;
  position: relative;
}

.navbar a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #ff0000;
  transition: width 0.3s ease;
}

.navbar a:hover::after {
  width: 100%;
}

.nav-button-conteiner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta {
  padding: 1rem;
}

.button {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.2) 0 2px 4px,
    rgba(45, 35, 66, 0.15) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 2rem;
  width: 8rem;
  justify-content: center;
  list-style: none;
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 1rem;
}

.button:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, 0.3) 0 4px 8px,
    rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

@media (max-width: 720px) {
  .header {
    margin: 0;
    padding: 1rem 2rem;
  }
  .header img {
    max-width: 20vw;
  }

  .cta {
    padding-right: 0;
  }

  .button {
    font-size: 1rem;
  }
}
