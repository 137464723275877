.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
}

.projects h2 {
  color: #ff0000;
  text-align: center;
  font-size: 3rem;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2rem;
  gap: 4rem;
}

.card {
  position: relative;
  width: 30em;
  height: 20em;
  margin: 1rem;
  background: linear-gradient(-45deg, #9500dae1 0%, #d30964 100%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card img {
  width: 29em;
  height: 19em;
  fill: #333;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #080808ec;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.card__content a {
  line-height: 2rem;
  text-decoration: none;
  color: #ff0000;
}
.card:hover .card__content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}

.card__title {
  margin: 0;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
}

.card__description {
  margin: 10px 0 0;
  font-size: 14px;
  color: #000000;
  line-height: 1.4;
}

.card:hover svg {
  scale: 0;
  transform: rotate(-45deg);
}

@media screen and (max-width: 720px) {
  .card {
    position: relative;
    width: 20em;
    height: 15em;
    margin: 1rem;
    background: linear-gradient(-45deg, #9500dae1 0%, #d30964 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .card img {
    width: 19em;
    height: 14em;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
}
